(function ($, emailjs, window, document, undefined) {

  'use strict';

  // close menu on navigation click
  var toggleMenu = (function ($) {
    jQuery('.click-mobile').click(function () {
      jQuery('#js-closeMenu').click();
    });
  });

  // Fix le header
  var fixHeader = function() {
    var $header = $('.header');
    $(window).scroll(function(){
      if ($(window).scrollTop() > 10 && !$header.hasClass('fixed') ) {
        $header.addClass('fixed');
      } else if ($(window).scrollTop() < 11 && $header.hasClass('fixed') ) {
        $header.removeClass('fixed');
      }
    });
  };

  var formCalltoAction = (function ($) {

    var $form = $('form#info_form');

    var showFormError = function () {
      $form.find('.form-error')
        .html('Veuillez vérifier vos réponses, certains champs sont vides ou non valides').show();
    };

    var hideFormError = function () {
      $form.find('.form-error').html('').hide();
    };

    var isEmail = function (email) {
      var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(email);
    };

    var isPhone = function (phone) {
      var regex = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
      return regex.test(phone);
    };

    // validate one field given its value and data type [email or phone]
    var validateField = function (v, dt) {
      var r = {
        'value': true,
        'message': ''
      };
      if (v === '') {
        r.value = false;
      }
      if (dt === 'email' && !isEmail(v)) {
        r.value = false;
      } else if (dt === 'phone' && !isPhone(v)) {
        r.value = false;
      }
      return r;
    };


    var validatePage = function ($page) {
      var $inputGroup = $page.find('.form-group');
      var valid = true;
      $inputGroup.each(function (i, e) {
        var $e = $(e);
        // Si checkbox ou radiobutton
        if ($e.hasClass('form-checkbox-list')) {
          if ($e.find('input:radio:checked').length === 0) {
            valid = false;
          }
        } else {
          $e.find('input, select').each(function (index, element) {
            var $input = $(element);
            var validateResult = validateField($input.val(), $input.data('type'));
            if (!validateResult.value) {
              valid = false;
            }
          });
        }
      });
      return valid;
    };

    var navigateToNextPage = function (currentTarget) {
      var $currentPage = $(currentTarget).closest('.lightbox-content.page');

      // Validate current page avant
      if (validatePage($currentPage)) {
        hideFormError();
        var $nextPage = $currentPage.next('.lightbox-content.page');
        $currentPage.attr('data-slide', 'hideLeft');
        $nextPage.attr('data-slide', 'show');
      } else {
        showFormError();
      }
    };

    var sendEmail = function (currentTarget) {
      navigateToNextPage(currentTarget);
      var service_id = "gmail_fungo";
      var template_id = "demo_form_clone";
      var $pageConfirm = $form.find('.page-confirm');
      var $currentPage = $form.find("[data-slide='show']");

      var templateParams = {
        question_1: $("input[type=radio][name=question_1]:checked").val(),
        prospect_fullname: $('input[name=prospect_fullname]').val(),
        prospect_mail: $('input[name=prospect_mail]').val(),
        prospect_phone_num: $('input[name=prospect_phone_num]').val(),
        prospect_comments: $('textarea[name=prospect_comments]').val(),
      };

      //console.log('templateParams', templateParams);

      emailjs.send(service_id, template_id, templateParams)
        .then(function (response) {
          //console.log('SUCCESS!', response.status, response.text);

          if (response.status === 200) {
            $currentPage.attr("data-slide", 'hideLeft');
            $pageConfirm.attr('data-slide', 'show');
            $pageConfirm.find('.wait').hide(function () {
              $pageConfirm.find('.message.success').show();
              //on success, reset form and flags
              $('form#info_form')[0].reset();
            });
          } else {
            $currentPage.attr("data-slide", 'hideLeft');
            $pageConfirm.attr('data-slide', 'show');
            $pageConfirm.find('.wait').hide(function () {
              $pageConfirm.find('.message.error').show();
            });
          }
        }, function (error) {
          console.log('error', error);
          $currentPage.attr("data-slide", 'hideLeft');
          $pageConfirm.attr('data-slide', 'show');
          $pageConfirm.find('.wait').hide(function () {
            $pageConfirm.find('.message.error').show();
          });
        });
    };

    var datajs = function (key, value) {
      return document.querySelectorAll('[data-' + key + '=' + value + ']');
    };

    var clickHandler = function (e) {
      e.preventDefault();
      var $currentPage = $(e.currentTarget).closest('.lightbox-content.page');
      if (validatePage($currentPage)) {
        //form is valid, get recapatcha response and store target globally
        sendEmail(e.currentTarget);
      } else {
        showFormError();
      }
    };

    var resetForm = function () {
      $('body').removeClass('no-overflow');
      setTimeout(function () {
        $form.find('.page').attr('data-slide', 'hideRight');
        $form.find('.page1').removeAttr('data-slide');
      }, 300);
    };

    var preventBodyScroll = function () {
      $('body').addClass('no-overflow');
    };

    var bindEvents = function () {
      $(datajs('type', 'submit')).on('click', clickHandler);
      $form.find('button.nextPage').on('click', function (e) {
        navigateToNextPage(e.currentTarget);
      });
      $('#close-lightbox').on('click', resetForm);
      $('a[href$="#f"]').on('click', preventBodyScroll);
    };

    var init = function () {
      bindEvents();
    };

    return {
      init: init
    };

  })(jQuery);

  formCalltoAction.init();
  toggleMenu();
  fixHeader();

})(jQuery, emailjs, window, document);
